 import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - menu
const Dashboard = () => import('@/views/menu/dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const User = () => import('@/views/menu/User')
const Role = () => import('@/views/menu/Role')
const Menu = () => import('@/views/menu/Menu')
const EProduct = () => import('@/views/menu/Document')
const EProductForm = () => import('@/views/menu/DocumentForm')
const DocumentView = () => import('@/views/menu/DocumentView')
const PerangkatDaerah = () => import('@/views/menu/PerangkatDaerah')
const DocumentType = () => import('@/views/menu/DocumentType')
const Kinerja = () => import('@/views/menu/Kinerja')
const Activity = () => import('@/views/menu/Activity')

Vue.use(Router)

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'User',
          name: 'Kelola User',
          component: User
        },
        {
          path: 'Role',
          name: 'Kelola Role / PD',
          component: Role
        },
        {
          path: 'Menu',
          name: 'Kelola Menu',
          component: Menu
        },
        {
          path: 'Document',
          name: 'Pengajuan Produk Hukum',
          component: EProduct
        },
        {
          path: 'DocumentView',
          name: 'Pengajuan Detail Produk Hukum',
          component: DocumentView
        },
        {
          path: 'DocumentFrom',
          name: 'Pengajuan Form Produk Hukum',
          component: EProductForm
        },
        {
          path: 'PerangkatDaerah',
          name: 'Kelola Perangkat Daerah',
          component: PerangkatDaerah
        },
        {
          path: 'DocumentType',
          name: 'Kelola Jenis Dokumen',
          component: DocumentType
        },
        {
          path: 'Kinerja',
          name: 'Kelola Kinerja',
          component: Kinerja
        },
        {
          path: 'Activity',
          name: 'Kelola Urusan',
          component: Activity
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
      ]
    }
  ]
}

