import Vue from 'vue'
import Vuex from 'vuex'
import auth from './module/auth'
import user from './module/user'
import menu from './module/menu'
import role from './module/role'
import document from './module/document'
import dashboard from './module/dashboard'
import perangkat_daerah from './module/perangkat_daerah'
import document_type from './module/document_type'
import kinerja from './module/kinerja'
import activity from './module/activity'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  modules: {
    auth,
    user,
    dashboard,
    menu,
    role,
    document,
    perangkat_daerah,
    document_type,
    kinerja,
    activity
  },
  mutations
})